export default {
    info: {
        title: '', // 页面title
        version: "1.1.001", // 软件版本号
        company: {
            name: "汇控创恒数据系统有限公司", // 公司名称
            url: "www.hckh.com.cn", // 公司网址
        },
        phone: "4001-552-661", // 服务电话
    },
    tab_page: {
        icon: "", // 标签页ICO
        name: "" // 标签页名称
    },
    login: {
        image: "", // 登录页图片
        name: "", // 登录页软件名称
        logo: "./static/images/login-logo.jpg", // 登录页logo
        timeout: 500 // 登出超时时间
    },
    logoPath: "./static/images/logo.jpg",
    style: {
        classic: {
            logo: "./static/images/login-logo.jpg", // 蓝色风格logo
            mobile_logo: "./static/images/small-logo.jpg"
        },
        business: {
            logo: "./static/images/login-logo.jpg", // 商务风格logo
            small_logo: "./static/images/small-logo.jpg",
            mobile_logo: "./static/images/small-logo.jpg"
        },
        simplicity: {
            logo: "./static/images/login-logo.jpg", // 平板风格logo
            mobile_logo: "./static/images/small-logo.jpg"
        }
    },
}