const AppealAcceptedroutes = [
    {
        path: '/engineeringManagement/appealaccepted',
        name: 'appealaccepted',
        meta: {
            title: '投诉受理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/AppealAccepted/AppealAccepted.vue')
    },
    {
        path: '/engineeringManagement/appealReply',
        name: 'appealReply',
        meta: {
            title: '诉求回复',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/AppealReply/AppealReply.vue')
    },
    {
        path: '/engineeringManagement/appealForwardHistory',
        name: 'appealForwardHistory',
        meta: {
            title: '诉求转发记录',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/AppealForwardHistory/AppealForwardHistory.vue')
    },
    {
        path: '/engineeringManagement/replyForwardHistory',
        name: 'replyForwardHistory',
        meta: {
            title: '回复转发记录',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ReplyForwardHistory/ReplyForwardHistory.vue')
    },
    {
        path: '/engineeringManagement/complain',
        name: 'complain',
        meta: {
            title: '投诉',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Appeal/complain.vue')
    },
    {
        path: '/engineeringManagement/appealDone',
        name: 'appealDone',
        meta: {
            title: '我的待办/已办',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Appeal/AppealDone.vue')
    }
]
export default AppealAcceptedroutes