// 查看用户组
const getUserGroupByParentId = 'auth/usergroup/getUserGroupByParentId';
const getUserByUserGroup = 'auth/user/findGroupUserByGroupId';
// 查询当前用户组下为某角色得所有用户
const findByCurrentuserid = 'auth/user/findByCurrentuserid'; //0班主任 1辅导员 2宿舍管理员 3楼宇管理员 
// 菜单业务  查看 
const MenuBusiness = 'auth/JurisdictionNavigation/queryAll';
const MenuBusiness2 = '/auth/JurisdictionNavigation/findAll';
// 根据业务 查一级模块菜单
const MenuByBard = 'auth/JurisdictionMenu/findByBarid';
//根据 父菜单id  查子菜单
const MenuByParentid = 'auth/JurisdictionMenu/findByParentid';
// 新增菜单
const addMenus = 'auth/JurisdictionMenu/add'
    // 修改菜单
const editMenus = 'auth/JurisdictionMenu/update'
    //根据菜单id  查按钮
const PointsByMenuid = 'auth/JurisdictionPoint/findByMenuid';
//根据菜单id  查API
const ApiByMenuid = 'auth/JurisdictionApi/findByMenuId';
//用户根据地理业务id查询位置
const findByGisbusinessidState = 'auth/gisinformation/findByGisbusinessidState';
//用户根据父节点id查询地理位置
const findChildNodeState = 'auth/gisinformation/findChildNodeState';

// 通过角色id 查用户
const usersByroleId = 'auth/role/users';
/**
 * 迎新stnyi
 */
// 查看组织定义
const corgandefinition = 'stnyi/welcomeNew/corgandefinition/list';
// 查询当前用户所拥有的组织定义
const corgraByUserid = 'stnyi/capprovalmanager/findByUserid';
// 查询班级不分页
const classNoPage = 'stnyi/welcomeNew/cclass/listNoPage';
// 查询新生不分页
const getnewStudentVolistNoPage = 'stnyi/welcomeNew/cnewstudent/getnewStudentVolistNoPage';
// 查询全部房间分页
const croomList = 'stnyi/dorm/croom/list';
// 查询全部房间不分页
const croomNoPage = 'stnyi/dorm/croom/listNoPage';
//当前登陆人可查到的建筑物map（）
const getGisMap = 'stnyi/dorm/croom/getGisMap';
// 根据建筑物id查询所有楼层
const getFloorByGisId = 'stnyi/dorm/croom/getFloorByGisId';
//根据宿舍id查询床位
const getbeds = 'stnyi/dorm/croom/findbednumberByRoomid';
// 查询迎新系统设置
const csyssetting = 'stnyi/csyssetting/select';
/**
 * 开发者
 */
const qrcodeSelect = 'interface/qrcodetree/select';

/**
 * 图片
 */
// 根据oid 查图片服务器
const imageById = 'uploadimg/image/findById';

/**
 * 终端
 * 
 */
//查询管理员列表
const adminlist = 'smartterminal/approvalmanager/select';
//查询总字典表
const selectSumlist = 'smartterminal/sumdictionaries/select';
//查询详情字典
const selectDetaillist = 'smartterminal/detaildictionaries/select';
//查询设备组数据
const selectDeviceGrouplist = 'smartterminal/devicegroup/select';
//查询设备数据
const selectDevicelist = 'smartterminal/device/select';
//查询我的自己的场所
const selectMysizelist = 'smartterminal/mysite/findByUserid';
//查询我的场所数据
const selectMysite = '/smartterminal/mysite/select';
//查询场所设置数据
const selectsitelist = 'smartterminal/siteset/select';
//查询访客数据
const selectvisitorlist = 'smartterminal/myvisitor/select';
//我的访客预约查询
const selectvisitororder = 'smartterminal/makeappointment/select';
//应用策略查询
const selectstrategy = 'smartterminal/usetactics/select';
//应用策略用户查询
const selectstrategyuser = 'smartterminal/tacticsuser/select'
    //应用策略设备查询
const selectstrategdevice = 'smartterminal/tacticsdevice/select'
    //应用策略表单查询 
const selectformlist = 'smartterminal/tacticsform/select'
    //应用策略表单类型查询
const selectformtype = 'smartterminal/tactocsformtype/select'
    //应用策略规则查询
const selectRule = 'smartterminal/rule/select'
    //组织场所管理员查询
const selectmanager = 'smartterminal/siteadmin/select'
    //前台管理员查询
const selectFrontmgr = 'smartterminal/visitorrecetion/select'

/**
 * 
 * cms
 */
//权限组查询
const selectPermissiongroup = 'cms/jurgroup/select'
    //权限用户查询
const selectPermissionuser = 'cms/juruser/select'
    //权限组栏目查询
const selectPermissioncolumn = 'cms/groupcolumn/select'
    //站点管理 查询
const selectsite = 'cms/websiteadmin/select'
    //无条件站点查询
const selectsiteno = 'cms/websiteadmin/selectnotjur'
    //网站设置 查询
const selectsiteset = 'cms/websitesetting/select'
    //接口设置 查询
const selectjiekou = 'cms/interfacesettings/select'
    //seo设置 查询
const selectseo = 'cms/seosetting/select'
    //内网设置 查询
const selectIntranet = 'cms/systemsettings/select'
    //栏目管理 查询
const selectcolumn = 'cms/programmanagement/select'
    //栏目管理 无条件查询
const selectcolumnno = 'cms/programmanagement/selectnotjur'
    //文章管理 查询
const selectarticle = 'cms/articlemanagement/select'
    //广告管理 查询
const selectadv = 'cms/advertisingmanagement/select'

const selectsinglepage = 'cms/singlepagemanagement/select'
    //产品管理 查询
const selectproductmg = 'cms/productcontrol/select'
    //友情链接 查询
const selectblogroll = 'cms/blogroll/select'
module.exports = {
    getUserGroupByParentId,
    findByCurrentuserid,
    MenuBusiness,
    MenuBusiness2,
    MenuByBard,
    addMenus,
    editMenus,
    MenuByParentid,
    PointsByMenuid,
    ApiByMenuid,
    corgandefinition,
    corgraByUserid,
    classNoPage,
    findByGisbusinessidState,
    getnewStudentVolistNoPage,
    findChildNodeState,
    croomList,
    croomNoPage,
    getGisMap,
    getFloorByGisId,
    csyssetting,
    imageById,
    qrcodeSelect,
    getUserByUserGroup,
    usersByroleId,
    adminlist,
    selectSumlist,
    selectDetaillist,
    selectDeviceGrouplist,
    selectDevicelist,
    selectMysizelist,
    selectsitelist,
    selectvisitorlist,
    selectvisitororder,
    selectstrategy,
    selectstrategyuser,
    selectstrategdevice,
    selectformlist,
    selectformtype,
    selectRule,
    selectmanager,
    selectMysite,
    selectFrontmgr,
    selectPermissiongroup,
    selectPermissionuser,
    selectPermissioncolumn,
    selectsite,
    selectsiteset,
    selectjiekou,
    selectseo,
    selectIntranet,
    selectcolumn,
    selectsiteno,
    selectcolumnno,
    selectarticle,
    selectadv,
    selectsinglepage,
    selectproductmg,
    selectblogroll,
    getbeds
}