<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  provide() {
    const screenWidth = document.body.clientWidth;
    let screenType = "computer";
    if (screenWidth > 1280) {
      screenType = "computer";
    } else if (screenWidth > 1000) {
      screenType = "ipad";
    } else {
      screenType = "mobile";
    }
    return {
      screenType: screenType,
      appModule: this,
    };
  },
  data() {
    return {
      tokenTimeout: null,
    };
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  mounted() {
    console.log('一直调的Listm在App.vue里面')
    // this.getEnterkey();
    // window.//console.log(document.body.clientWidth);
    // window.//console.log(this.screenType);
    // 设置页面title
    // if (this.$baseConfig.info.title) {
    //   document.querySelector("title").innerHTML = this.$baseConfig.info.title;
    // }
    // if (sessionStorage.getItem("token")) {
    //   // this.setTokenTimeout();
    // }
  },
  methods: {
    recordToken(token) {
      sessionStorage.setItem("token", token);
      // this.setTokenTimeout();
      // document.documentElement.addEventListener("click", this.domListenerFn);
    },
    //根据域名获取组织
    getEnterkey() {
      let url = window.location.href;
      this.$remote
        .post("/auth/layoutSetting/listm", {
          domainname: url,
          layouttype: "1",
        })
        .then(
          (res) => {
            if (res.code == 20000) {
              // this.formboard = res.data.data[0].formboard;
              this.sysname = res.data.data[0].tagname;

              document.querySelector("title").innerHTML = this.sysname;

              // var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
              // link.type = 'image/x-icon'
              // link.rel = 'shortcut icon'
              // link.href = this.icon

              var link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
              link.type = "image/x-icon";

              link.rel = "shortcut icon";
              link.href = res.data.data[0].icon;
              document.getElementsByTagName("head")[0].appendChild(link);
              // debugger
              // document.getElementsByTagName('head')[0].appendChild(link)
            } else if (res.code == 20001) {
              this.$message.error(res.message);
            } else {
              this.$message.error("获取失败");
            }
          },
          (rej) => {
            this.$alert(rej);
          }
        );
    },
    // 退出系统
    logout() {
      this.$confirm("是否退出？", "", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.logoutFun();
        })
        .catch((action) => {});
    },
    logoutFun() {
      this.$remote
        .get("auth/user/logout")
        .then((res) => {
          //console.log(res);
          if (res.code == 20000) {
            sessionStorage.clear();
            localStorage.clear();
            this.$router.push("/login");
            // this.$message.success("退出成功");
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          // this.common.toast('删除失败，请重新操作');
        });
    },
    // 登录状态过期时间
    // domListenerFn() {
    //   this.clearTokenTimeout();
    //   this.setTokenTimeout();
    // },
    // setTokenTimeout() {
    //   const time = new Date().getTime();
    //   sessionStorage.setItem("lastTime", time);
    //   this.tokenTimeout = setTimeout(this.timeoutFn, 1000);
    // },

    // timeoutFn() {
    //   const time = new Date().getTime();
    //   const oldTime = sessionStorage.getItem("timeout");
    //   if (time - oldTime > this.$baseConfig.login.timeout * 1000) {
    //     sessionStorage.removeItem("token");
    //     this.clearTokenTimeout();
    //     document.documentElement.removeEventListener(
    //       "click",
    //       this.domListenerFn
    //     );
    //     this.$alert("登录超时，请重新登录", {
    //       callback: () => {
    //         this.$router.push("/login");
    //       }
    //     });
    //   } else {
    //     this.tokenTimeout = setTimeout(this.timeoutFn, 1000);
    //   }
    // },
    // clearTokenTimeout() {
    //   clearTimeout(this.tokenTimeout);
    // }
  },
};
</script>
<style lang="css">
/* .el-tooltip__popper {
  max-width: 50%;
} */
</style>
<style lang="less">
@import url("common/zcm-main.css");
@import url("common/iconfont.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // color: white;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif, "宋体";
}
</style>
