const engineeringManagementRoutes = [
    {
        path: '/engineeringManagement/investbasicData',
        name: 'investbasicData',
        meta: {
            title: '进度管理/信息填报/基础数据上报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestbasicData/InvestbasicData.vue')
    },
    {
        path: '/engineeringManagement/invesitmentPlanReport',
        name: 'invesitmentPlanReport',
        meta: {
            title: '进度管理/信息填报/投资计划上报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestmentPlanReport/InvestmentPlanReport.vue')
    },
    {
        path: '/engineeringManagement/constructionPlanReport',
        name: 'constructionPlanReport',
        meta: {
            title: '进度管理/信息填报/施工计划上报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionPlanReport/ConstructionPlanReport.vue')
    },


    {
        path: '/engineeringManagement/constructionReport',
        name: 'constructionReport',
        meta: {
            title: '进度管理/信息填报/施工报备',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionReport/ConstructionReport.vue')
    },
    {
        path: '/engineeringManagement/constructionProgressReport',
        name: 'constructionProgressReport',
        meta: {
            title: '进度管理/信息填报/工程形象进度',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionProgressReport/ConstructionProgressReport.vue')
    },
    {
        path: '/engineeringManagement/procedure',
        name: 'procedure',
        meta: {
            title: '进度管理/信息填报/手续上报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Procedure/Procedure.vue')
    },
    {
        path: '/engineeringManagement/procedureReview',
        name: 'procedureReview',
        meta: {
            title: '进度管理/进度审核/手续上报审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ProcedureReview/Procedure.vue')
    },
    {
        path: '/engineeringManagement/surveillanceVideo',
        name: 'surveillanceVideo',
        meta: {
            title: '项目管理/监控视频',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SurveillanceVideo/SurveillanceVideo.vue')
    },
    {
        path: '/engineeringManagement/InvestmentReview',
        name: 'InvestmentReview',
        meta: {
            title: '项目管理/投资进度审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestmentReview/InvestmentReview.vue')
    },
    {
        path: '/engineeringManagement/InvestmentReview/review/:id',
        name: 'InvestmentReviewForm',
        meta: {
            title: '项目管理/投资进度审核/审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestmentReview/InvestmentReviewForm.vue')
    },
    {
        path: '/engineeringManagement/ConstructionReview',
        name: 'ConstructionReview',
        meta: {
            title: '项目管理/施工进度审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionReview/ConstructionReview.vue')
    },
    {
        path: '/engineeringManagement/ConstructionReview/review/:id',
        name: 'ConstructionReviewForm',
        meta: {
            title: '项目管理/投资进度审核/审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionReview/ConstructionReviewForm.vue')
    },
    {
        path: '/engineeringManagement/mySupervisor',
        name: 'mySupervisor',
        meta: {
            title: '项目管理/我的督办',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/MySupervisor/MySupervisor.vue')

    },
    {
        path: '/engineeringManagement/supervisionForwarding',
        name: 'supervisionForwarding',
        meta: {
            title: '项目批示/批示转发',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SupervisionForwarding/SupervisionForwarding.vue')

    },
    {
        path: '/engineeringManagement/SupervisionHistory',
        name: 'SupervisionHistory',
        meta: {
            title: '项目批示/批示转发历史',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SupervisionHistory/SupervisionHistory.vue')

    },
    {
        path: '/engineeringManagement/ReplyForwarding',
        name: 'ReplyForwarding',
        meta: {
            title: '项目批示/批阅转发',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ReplyForwarding/ReplyForwarding.vue')

    },
    {
        path: '/engineeringManagement/ReplyHistory',
        name: 'ReplyHistory',
        meta: {
            title: '项目批示/批阅转发历史',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ReplyHistory/ReplyHistory.vue')

    },
    {
        path: '/engineeringManagement/MyTodolist',
        name: 'MyTodolist',
        meta: {
            title: '项目管理/我的待办',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/MyTodolist/MyTodolist.vue')

    },
    {
        path: '/engineeringManagement/approve',
        name: 'approve',
        meta: {
            title: '项目管理/我的审批',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Approve/approve.vue')

    },
    {
        path: '/engineeringManagement/MyDonelist',
        name: 'MyDonelist',
        meta: {
            title: '项目管理/我的已办',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/MyDonelist/MyDonelist.vue')

    },
    {
        path: '/engineeringManagement/ProjectManagement',
        name: 'ProjectManagement',
        meta: {
            title: '项目管理/项目管理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ProjectManagement/ProjectManagement.vue')
    },
    {
        path: '/engineeringManagement/investmentPlan',
        name: 'investmentPlan',
        meta: {
            title: '进度管理/进度审核/投资计划审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestmentPlan/InvestmentPlan.vue')
    },
    {
        path: '/engineeringManagement/constructionPlan',
        name: 'constructionPlan',
        meta: {
            title: '进度管理/进度审核/施工计划审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionPlan/ConstructionPlan.vue')
    },
    {
        path: '/engineeringManagement/leader',
        name: 'leader',
        meta: {
            title: '项目管理/包联领导',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Leader/Leader.vue')
    },
    {
        path: '/engineeringManagement/projectLeader',
        name: 'projectLeader',
        meta: {
            title: '项目管理/项目负责人',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ProjectLeader/ProjectLeader.vue')
    },
    {
        path: '/engineeringManagement/investmentProgress',
        name: 'investmentProgress',
        meta: {
            title: '进度管理/进度审核/投资投资进度',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/InvestmentProgress/InvestmentProgress.vue')
    },
    {
        path: '/engineeringManagement/buildReport',
        name: 'buildReport',
        meta: {
            title: '进度管理/进度审核/施工报备审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/BuildReport/BuildReport.vue')
    },
    {
        path: '/engineeringManagement/projectDeputyTable',
        name: 'projectDeputyTable',
        meta: {
            title: '进度管理/进度审核/基础信息审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ProjectDeputyTable/ProjectDeputyTable.vue')
    },
    {
        path: '/engineeringManagement/constructionProjress',
        name: 'constructionProjress',
        meta: {
            title: '进度管理/进度审核/施工进度审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionProjress/ConstructionProjress.vue')
    },
    {
        path: '/engineeringManagement/buildUnit',
        name: 'buildUnit',
        meta: {
            title: '项目管理/建设单位',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/BuildUnit/BuildUnit.vue')
    },
    {
        path: '/engineeringManagement/designUnit',
        name: 'designUnit',
        meta: {
            title: '项目管理/设计单位',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/DesignUnit/DesignUnit.vue')
    },
    {
        path: '/engineeringManagement/constructionUnit',
        name: 'constructionUnit',
        meta: {
            title: '项目管理/施工单位',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ConstructionUnit/ConstructionUnit.vue')
    },
    {
        path: '/engineeringManagement/annualPlan',
        name: 'annualPlan',
        meta: {
            title: '项目管理/年度计划',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/AnnualPlan/AnnualPlan.vue')
    },
    {
        path: '/engineeringManagement/organizationalUnit',
        name: 'organizationalUnit',
        meta: {
            title: '项目管理/组织单位',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/OrganizationalUnit/OrganizationalUnit.vue')
    },
    {
        path: '/engineeringManagement/supervisorUnit',
        name: 'supervisorUnit',
        meta: {
            title: '项目管理/监理单位',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SupervisorUnit/SupervisorUnit.vue')
    },
    {
        path: '/Video',
        name: 'Video',
        meta: {
            title: '监控播放器',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Video/Video.vue')

    },
    {
        path: '/VideoReview',
        name: 'VideoReview',
        meta: {
            title: '监控回看',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Video/VideoReview.vue')

    },
    {
        path: '/ReportProcedureSetting',
        name: 'ReportProcedureSetting',
        meta: {
            title: '手续上报优选项',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/ReportProcedure/ReportProcedureSetting.vue')

    },
]
export default engineeringManagementRoutes