<template>
    <div class="level-bread">
      <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item,index) in realList" :to="item.path" :key="index">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb> -->
       <el-breadcrumb class="breadcrumb-container" separator-class="el-icon-arrow-right" style="font-size:15px">
            <el-breadcrumb-item v-for="item in realList" :key="item.path" :to="item.path">{{item.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
    export default {
      name: "lelve-bread",
      data() {
        return {
          realList: []
        }
      },
      methods:{
        getRoutePath() {
          let matched = this.$route.matched.filter(item => item.name)
          // //console.log(this.$route.matched);
                this.realList = matched;
         }
      },
        watch: {
            $route() {
                this.getRoutePath()
            }
        },
        created(){
            this.getRoutePath()
        }
    }
</script>
