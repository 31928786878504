<template>
    <div class="width-1 a">
      <div class="height-3 py-2  box_size d-flex a-center j-start fang" style="position:relative">
        <!-- <el-divi  direction="vertical"></el-divi  der> -->
        <span ref="title" style="font-size:15px">{{headerText}}</span>
        <div id="line" class="aaaaaa" :style="{width: operatewidth + 'px' 
        }"
   ></div>
        </div>
        <el-divider ></el-divider>
    </div>
</template>

<script>
  // console.log(document.getElementById("title").clientWidth)
  // console.log(document.getElementById("line").style.width)
    export default {
      name: "Headtitle",
      props:{
          headerText:String
      },
      data() {
        return {
          operatewidth:''
        }
      },
      mounted(){
        this.width()
      },
      methods:{
        width(){
          this.operatewidth = this.$refs.title.clientWidth 
        }
           
      },
    }
</script>
<style lang="less" scoped>
.a{
  & /deep/ .el-divider--horizontal{
    height: 2px!important;
  }
}
.aaaaaa{
  position: absolute;
  left: 0;
  bottom:-2px;
  height: 2px;
  background-color: #409eff;
  z-index: 1;
}
</style>