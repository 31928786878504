// import Vue from 'vue'
// import Vuex  from 'vuex'
// 使用vuex
Vue.use(Vuex);

//创建vue实例
const store = new Vuex.Store({
    state: {
        permission: [],
        permssList: {},
        // PC菜单信息
        modleList: {},
        //app菜单信息
        appModels: {},
        // 排队信息
        lineuplist: {},
        activeArr: [0, 0],
        //cms
        articlecolumnid: '',
        procolumnid: '',
        singlecolumnid: '',
        advcolumnid: '',
        linkcolumnid: '',
        clumnid: '',
        siteid: '',
        advanseid: '',
        blalogid: '',
        singleid: '',
        productid: '',
        classificationid: '',
        facekeyid: '',
        // 大屏中间下方的数据
        channelArr: [],
        //moban
        temolateid: '',
    },
    mutations: {
        SET_PERMISSION(state, permission) { //permission 为传入的权限标识集合
            // 传入的权限集合 赋值给状态中的 permission
            state.permission = permission;
        },
        SET_ACTIVE(state, val) {
            state.activeArr = val
        },
        // 角色权限
        setpermsId(state, list) {
            state.permssList = list
        },
        // PC模块菜单
        setModleList(state, list) {
            state.modleList = list
        },
        setAppModleList(state, list) {
            state.appModels = list
        },
        setlineuplist(state, list) {
            state.lineuplist = list;
        },
        setartcolumn(state, val) {
            state.articlecolumnid = val
        },
        setproscolumn(state, val) {
            state.procolumnid = val
        },
        setsinglecolumn(state, val) {
            state.singlecolumnid = val
        },
        setadvcolumn(state, val) {
            state.advcolumnid = val
        },
        setlinkcolumn(state, val) {
            state.linkcolumnid = val
        },
        setclumnid(state, val) {
            state.clumnid = val
        },
        setsite(state, val) {
            state.siteid = val
        },
        setadcontent(state, val) {
            state.advanseid = val
        },
        setblalog(state, val) {
            state.blalogid = val
        },
        setsingle(state, val) {
            state.singleid = val
        },
        setproduct(state, val) {
            state.productid = val
        },
        setclassification(state, val) {
            state.classificationid = val
        },
        setfacekeyid(state, val) {
            state.facekeyid = val
        },
        setChannelArr(state, list) {
            state.channelArr = list
        },
        settemolate(state, val) {
            state.temolateid = val
        }

    },
    actions: { // 官方不推荐直接修改mutation， 可使用actions来提交 mutation
        SET_PERMISSION(context, permission) {
            // 提交到 mutation 中的 SET_PERMISSION 函数
            context.commit("SET_PERMISSION", permission);
        },
        setChannelArr(context, list) {
            console.log('正在更改store', list)
            context.commit("setChannelArr", list)
        }
    },
    getters: {
        channelArr(state) {
            return state.channelArr
        }
    }
})

//导出store
export default store