import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import router from "./router";
import api from "@/https/api";
import store from "./store/index.js";
import lrz from "lrz";
// Vue.use(VueBaiduMap.default, {
//     ak: 'YOUR_APP_KEY',
// })
// 公共方法
import common from "./common/common.js";
//css3动画
import animated from "animate.css";
Vue.use(animated);
//复制功能
import VueClipboard from "vue-clipboard2";

import config from "./config";
import VueContextMenu from "@xunlei/vue-context-menu";
//身份证
import IdCard from "./common/idCard";
//面包屑
import BreadCrumb from "@/components/BreadCrumb";
// 页面标题
import Headtitle from "@/components/common/Headtitle";

// 表格
import LbTable from "@/components/lb-table/lb-table";
// 文本
import wanEditor from "@/components/wanEditor2";

// 按钮组件 全局使用
import KtButton from "@/components/common/ktButton.vue";

Vue.component("KtButton", KtButton);

// / 通知书内组件
import vdr from "vue-draggable-resizable-gorkys";
import "vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css";
Vue.component("vdr", vdr);

//swiper轮播

import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import qs from "qs";

Vue.component("wanEditor", wanEditor);
Vue.component("BreadCrumb", BreadCrumb);
Vue.component("Headtitle", Headtitle);
Vue.component("LbTable", LbTable);
import dataV from "@jiaminghi/data-view";
Vue.use(dataV);
Vue.prototype.validid = IdCard; //验证身份证
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.prototype.$qs = qs;
Vue.prototype.$api = api;
Vue.use(ElementUI, { size: "small" });
Vue.use(VueContextMenu);
Vue.use(VueClipboard);
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

var routeList = [];
router.beforeEach((to, from, next) => {
  var index = -1;
  for (var i = 0; i < routeList.length; i++) {
    if (routeList[i].name == to.name) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    //如果存在路由列表，则把之后的路由都删掉
    routeList.splice(index + 1, routeList.length - index - 1);
  } else if (to.name != "登录") {
    routeList.push({ name: to.name, path: to.fullPath });
  }
  to.meta.routeList = routeList;
  next();
});

// 登录超时
Vue.prototype.overTime = () => {
  var lastTime = new Date().getTime();
  var currentTime = new Date().getTime();
  var timeOut = 300 * 1000; //设置超时时间： 5分
  sessionStorage.setItem("lastTime", new Date().getTime());
  window.document.onmousedown = function () {
    sessionStorage.setItem("lastTime", new Date().getTime());
  };

  function checkTimeout() {
    currentTime = new Date().getTime(); //更新当前时间
    lastTime = sessionStorage.getItem("lastTime");
    if (currentTime - lastTime > timeOut) {
      //判断是否超时
      if (window.int != "undefined") {
        window.clearInterval(window.int);
      }
      //console.log('超时未操作')
      //李娜修改，超时退出
      sessionStorage.clear();
      //Mindex 页面不跳转到登录页 Yrx
      let url = window.location.href;
      if (url.indexOf("/M/newIndex") != -1) {
        router.push("/M/newIndex");
        console.log("//Mindex 页面不跳转到登录页");
      } else if (url.indexOf("/M/noIndex") != -1) {
        router.push("/M/noIndex");
      } else {
        router.push("/login");
      }
    }
  } /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.int = setInterval(function () {
    checkTimeout();
  }, 10000);
};
