import axios from "@/config/axios.conf";

import { csyssetting } from "@/https/api.js"
import { isChina } from '@/utils/validate.js'

// import { reject } from "core-js/fn/promise";
export default {
    // 迎新总字典表、属性
    getStnyiDictions(val) {
        return new Promise((resolve, reject) => {
            axios.post("stnyi/sumdictionaries/select", {
                page: '1',
                size: '1000',
            }).then(res => {
                this.sumdictions = [];
                if (res.code == 20000) {
                    if (res.data.list) {
                        let oid = res.data.list.filter(it => it.serialnum == val)[0].oid;
                        if (oid) {
                            axios.post("stnyi/detaildictionaries/select", {
                                page: '1',
                                size: '1000',
                                sumdictionariesid: oid
                            }).then(res => {
                                if (res.code == 20000) {
                                    if (res.data.list) {
                                        res.data.list.sort((a, b) => {
                                            return a.serialnum - b.serialnum
                                        });
                                        resolve(res.data.list);
                                    }
                                }
                            });
                        }
                    }
                }
            });
        })
    },
    // 内控 '1' /配置'0'字典 、属性表
    getIntercontrol(val, num) {
        return new Promise((resolve, reject) => {
            axios.post("auth/sumdictionaries/select", {
                page: '1',
                size: '1000',
                sign: num
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        let oid = res.data.list.filter(it => it.serialnum == val)[0].oid;
                        if (oid) {
                            axios.post("auth/ainternaldictionaries/select", {
                                page: '1',
                                size: '1000',
                                sumdictionariesid: oid
                            }).then(res => {
                                if (res.code == 20000) {
                                    if (res.data.list) {
                                        res.data.list.sort((a, b) => {
                                            return a.serialnum - b.serialnum
                                        });
                                        resolve(res.data.list);
                                    }
                                }
                            });
                        }
                    }
                }
            });
        });
    },
    // 查内控详情字典
    getXiangqing(val) {
        return new Promise((resolve, reject) => {
            axios.post("auth/ainternaldictionaries/select", {
                page: '1',
                size: '1000',
                serialnum: val
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        res.data.list.sort((a, b) => {
                            return a.serialnum - b.serialnum
                        });
                        resolve(res.data.list);
                    }
                }
            });
        })
    },
    // 开发者总字典表属性
    getDeveldictionary(val) {
        return new Promise((resolve, reject) => {
            axios.post("interface/sumdictionaries/select", {
                page: '1',
                size: '1000'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        let oid = res.data.list.filter(it => it.serialnum == val)[0].oid;
                        if (oid) {
                            axios.post("interface/detaildictionaries/select", {
                                page: '1',
                                size: '1000',
                                sumdictionariesid: oid
                            }).then(res => {
                                if (res.code == 20000) {
                                    if (res.data.list) {
                                        res.data.list.sort((a, b) => {
                                            return a.serialnum - b.serialnum
                                        });
                                        resolve(res.data.list);
                                    }
                                }
                            });
                        }
                    }
                }
            });
        });
    },
    // CMS总字典表属性
    getCMSdictionary(val) {
        return new Promise((resolve, reject) => {
            axios.post("cms/sumdictionaries/select", {
                page: '1',
                size: '1000'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        let oid = res.data.list.filter(it => it.serialnum == val)[0].oid;
                        if (oid) {
                            axios.post("cms/detaildictionaries/select", {
                                page: '1',
                                size: '1000',
                                sumdictionariesid: oid
                            }).then(res => {
                                if (res.code == 20000) {
                                    if (res.data.list) {
                                        res.data.list.sort((a, b) => {
                                            return a.serialnum - b.serialnum
                                        });
                                        resolve(res.data.list);
                                    }
                                }
                            });
                        }
                    }
                }
            });
        });
    },
    // 智能终端总字典表属性
    getterminalnary(val) {
        return new Promise((resolve, reject) => {
            axios.post("smartterminal/sumdictionaries/select", {
                page: '1',
                size: '1000'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        let oid = res.data.list.filter(it => it.serialnum == val)[0].oid;
                        if (oid) {
                            axios.post("smartterminal/detaildictionaries/select", {
                                page: '1',
                                size: '1000',
                                sumdictionariesid: oid
                            }).then(res => {
                                if (res.code == 20000) {
                                    if (res.data.list) {
                                        res.data.list.sort((a, b) => {
                                            return a.serialnum - b.serialnum
                                        });
                                        resolve(res.data.list);
                                    }
                                }
                            });
                        }
                    }
                }
            });
        });
    },
    // 查询当前用户所拥有的组织定义
    getCurrentOrga() {
        return new Promise((resolve, reject) => {
            let definition = '';
            axios.get(this.$api.corgraByUserid).then(res => {
                if (res.code == 20000) {
                    if (res.data && res.data.usergoupName) {
                        if (res.data.definitionclassName) {
                            definition = '(' + res.data.definitionclassName + ')';
                        }
                        let lists = {
                            oid: res.data.oid,
                            name: res.data.usergoupName + definition,
                            usergoupid: res.data.usergoupid,
                            definitionclas: res.data.definitionclass,
                            children: [],
                            definitionclass: res.data.definitionclass
                        };
                        resolve(lists);
                    }
                }
            });
        })
    },
    // 获取当前组织的组织结构（当前人领导组织定义/班级）
    getuserByrole() {
        return new Promise((resolve, reject) => {
            axios.post('stnyi/welcomeNew/corgandefinition/getUsergroupByCurrentUserId', {}).then(res => {
                if (res.code == 20000) {
                    if (res.data) {
                        let list = Object.keys(res.data)[0];
                        switch (list) {
                            case '0':
                                let array = [];
                                let infos = Object.values(res.data)[0];
                                if (infos) {
                                    let child = { children: [] };
                                    infos.forEach(it => {
                                        let lists = {
                                            ...it,
                                            ...child
                                        };
                                        array.push(lists)
                                    });
                                    resolve(array);
                                };
                                break;
                            case '1':
                                let arr = [];
                                let aaaa = Object.values(res.data)[0];
                                for (const key in aaaa) {
                                    if (aaaa.hasOwnProperty(key)) {
                                        const ele = aaaa[key];
                                        if (ele) {
                                            let eles = {
                                                oid: key,
                                                usergoupName: ele + '(班级)',
                                                usergoupid: key,
                                                definitionclass: '1',
                                                icon: "icon-boshimao",
                                                sign: 1,
                                            };
                                            arr.push(eles)
                                            resolve(arr);
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
            })
        });
    },
    // 迎新建筑GIS业务
    getGisMap() {
        return new Promise((resolve, reject) => {
            axios.post(csyssetting, {
                page: '1',
                size: '10'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list.length > 0) {
                        resolve(res.data.list[0].gisbusiness)
                    };
                }
            });
        })
    },
    // 智能终端建筑GIS业务
    getTerGisMap() {
        return new Promise((resolve, reject) => {
            axios.post('smartterminal/syssetting/select', {
                page: '1',
                size: '10'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list.length > 0) {
                        resolve(res.data.list[0].businessid)
                    };
                }
            });
        })
    },
    // 获取等级
    getGrage() {
        return new Promise((resolve, reject) => {
            axios.post('stnyi/customorgandefinition/select', {
                page: '1',
                size: '100'
            }).then(res => {
                if (res.code == 20000) {
                    if (res.data.list) {
                        resolve(res.data.list);
                    }
                }
            })
        })
    },
    // 根据oid查服务器图片数据   数组
    getIconInfo(val) {
        return new Promise((resolve, reject) => {
            // axios.post(imageById, val).then(res => {
            //     if (res.code == 20000) {
            //         if (res.data) {
            //             resolve(res.data);
            //         }
            //     }
            // })
        })
    },
    // 右键菜单样式
    styleMenu(menu, event) {
        if (event.clientX > 1800) {
            menu.style.left = event.clientX - 100 + 'px';
        } else {
            menu.style.left = event.clientX + 1 + 'px';
        }
        document.addEventListener('click', this.foo); // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        if (event.clientY > 700) {
            menu.style.top = event.clientY - 30 + 'px';
        } else {
            menu.style.top = event.clientY - 10 + 'px';
        }
    },
    /**
     * 收起节点
     */
    toClose(arrary) {
        let self = this;
        let treeList = arrary;
        for (let i = 0; i < treeList.length; i++) {
            self.$refs.treeForm.store.nodesMap[arrary[i].oid].expanded = false;
        }
    },
    // 转换时间
    transferTime(time) {
        var moment = require('moment');
        return moment(time).format("YYYY-MM-DD hh:mm:ss");
        // return moment.parseZone(time).format("YYYY-MM-DD HH:mm:ss");
    },
    // 转换时间
    transferTime1(time) {
        var moment = require('moment');
        // return moment(time).format("YYYY-MM-DD hh:mm:ss");
        return moment.parseZone(time).format("YYYY-MM-DD HH:mm:ss");
    },
}