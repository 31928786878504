import Vue from "vue";
import baseConfig from "./base.conf";

import axios from "./axios.conf";

Vue.prototype.$remote = axios;
Vue.prototype.$baseConfig = baseConfig;

/**
 *   @description 配置文件
 *   @author Xiaohui Zeng
 *   @date 2019/10/5
 */
// 默认系统名称
export const siteTitle = '欢迎登录'
// 默认首页
// 默认登录页
export const loginPage = 'login'