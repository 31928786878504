const sysSettingRoutes = [
    {
        path: '/engineeringManagement/region',
        name: 'region',
        meta: {
            title: '系统设置/区域管理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/region.vue')

    },
    {
        path: '/engineeringManagement/classify',
        name: 'classify',
        meta: {
            title: '项目管理/分类管理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/classify.vue')

    },
    {
        path: '/engineeringManagement/personmanagement',
        name: 'personmanagement',
        meta: {
            title: '项目管理/人员管理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/personmanagement.vue')

    },
    {
        path: '/engineeringManagement/IndustryManagement',
        name: 'IndustryManagement',
        meta: {
            title: '项目管理/行业管理',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/IndustryManagement.vue')

    },
    {
        path: '/engineeringManagement/progressauditor',
        name: 'progressauditor',
        meta: {
            title: '项目管理/进度审核员',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/progressauditor.vue')

    },
    {
        path: '/engineeringManagement/handleAuditor',
        name: 'handleAuditor',
        meta: {
            title: '项目管理/督办秘书',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/handleAuditor.vue')

    },
    {
        path: '/engineeringManagement/warnAuditor',
        name: 'warnAuditor',
        meta: {
            title: '项目管理/预警管理员',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/warnAuditor.vue')

    },
    {
        path: '/engineeringManagement/appealAuditor',
        name: 'appealAuditor',
        meta: {
            title: '项目管理/申诉受理员',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/appealAuditor.vue')
    },
    {
        path: '/engineeringManagement/baolianLeader',
        name: 'baolianLeader',
        meta: {
            title: '项目管理/包联领导',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/baolianLeader.vue')
    },
    {
        path: '/engineeringManagement/streamingserver',
        name: 'streamingserver',
        meta: {
            title: '项目管理/流媒体服务器',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/streamingserver.vue')
    },
    {
        path: '/engineeringManagement/warnInvest',
        name: 'warnInvest',
        meta: {
            title: '项目预警/投资预警',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Warning/warnInvest.vue')

    },
    {
        path: '/engineeringManagement/warnBuild',
        name: 'warnBuild',
        meta: {
            title: '项目预警/施工预警',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Warning/warnBuild.vue')
    },
    {
        path: '/engineeringManagement/warnProject',
        name: 'warnProject',
        meta: {
            title: '项目预警/项目预警',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Warning/warnProject.vue')
    },
    {
        path: '/engineeringManagement/progress',
        name: 'progress',
        meta: {
            title: '项目管理/投资进度上报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/Progress/progress.vue')
    },
    {
        path: '/engineeringManagement/reportSetFill',
        name: 'reportSetFill',
        meta: {
            title: '系统管理/上报设置填报',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/ReportSetFill/ReportSetFill.vue')

    },
    {
        path: '/engineeringManagement/reportSetReview',
        name: 'reportSetReview',
        meta: {
            title: '系统管理/上报设置审核',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/ReportSetReview/ReportSetReview.vue')

    },
    {
        path: '/engineeringManagement/systemSet',
        name: 'systemSet',
        meta: {
            title: '系统管理/系统设置',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/SystemSet/SystemSet.vue')

    },
    {
        path: '/engineeringManagement/dictionary',
        name: 'dictionary',
        meta: {
            title: '系统管理/字典',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/Dictionary/Dictionary.vue')

    },
    {
        path: '/engineeringManagement/projectClassify',
        name: 'projectClassify',
        meta: {
            title: '系统管理/项目分类入库',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/projectClassify.vue')
    },
    {
        path: '/engineeringManagement/warnSetting',
        name: 'warnSetting',
        meta: {
            title: '系统管理/提醒周期设置',
            icon: '',
            noCache: true
        },
        component: () =>
            import('@/pages/EngineeringManagement/SysSetting/warnSetting.vue')
    },
]
export default sysSettingRoutes