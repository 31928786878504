<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div
      ref="editor"
      class="text"
      :style="{ height: height, maxHeight: height }"
    ></div>
  </div>
</template>

<script>
import E from "wangeditor";

export default {
  name: "editoritem",
  data() {
    return {
      // uploadPath,
      editor: null,
      info_: null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100px",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    value: function (value) {
      if (value) {
        this.editor.txt.html(this.value);
      }
    },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted() {
    this.seteditor();
    this.editor.txt.html(this.value);
  },
  methods: {
    seteditor() {
      const me = this;
      // http://192.168.2.125:8080/admin/storage/create
      this.editor = new E(".editor");
      this.editor.customConfig.showLinkImg = false;
      // this.editor.customConfig.uploadImgShowBase64 = false; // base 64 存储图片
      // this.editor.customConfig.uploadImgServer =
      //   "https://services.hkchcloud.com/uploading/uploadimg/file/uploadimg";
      // "http://otp.cdinfotech.top/file/upload_images"; // 配置服务器端地址
      // this.editor.customConfig.uploadImgHeaders = {}; // 自定义 header
      // this.editor.customConfig.uploadFileName = "file"; // 后端接受上传文件的参数名
      // this.editor.customConfig.serialnumber = "5"; // 后端接受上传文件的参数名
      // this.editor.customConfig.ContentType = ".png"; // 后端接受上传文件的参数名
      // this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      // this.editor.customConfig.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      // this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      const SINA_URL_PATH =
        "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal";
      // this.editor.customConfig.showFullScreen = true; //
      (this.editor.customConfig.customUploadImg = function (
        resultFiles,
        insertImgFn
      ) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        me.filesToBase64(resultFiles);
      }),
        // 配置菜单
        (this.editor.customConfig.menus = [
          "head", // 标题
          "bold", // 粗体
          "fontSize", // 字号
          "fontName", // 字体
          "italic", // 斜体
          "underline", // 下划线
          "strikeThrough", // 删除线
          "foreColor", // 文字颜色
          "backColor", // 背景颜色
          "link", // 插入链接
          "list", // 列表
          "justify", // 对齐方式
          "quote", // 引用
          "emoticon", // 表情
          "image", // 插入图片
          "table", // 表格
          "video", // 插入视频
          "code", // 插入代码
          "undo", // 撤销
          "redo", // 重复
          "fullscreen", // 全屏
        ]);
      // 配置字体
      (this.editor.customConfig.fontNames = [
        "黑体",
        "仿宋",
        "楷体",
        "标楷体",
        "华文仿宋",
        "华文楷体",
        "宋体",
        "微软雅黑",
        "Arial",
        "Tahoma",
        "Verdana",
        "Times New Roman",
        "Courier New",
      ]),
        //配置字号
        (this.editor.customConfig.fontSizes = {
          "x-small": { name: "10px", value: "1" },
          small: { name: "13px", value: "2" },
          normal: { name: "16px", value: "3" },
          large: { name: "18px", value: "4" },
          "x-large": { name: "24px", value: "5" },
          "xx-large": { name: "32px", value: "6" },
          "xxx-large": { name: "48px", value: "7" },
        }),
        // 配置行高
        (this.editor.customConfig.lineHeights = [
          "1",
          "1.15",
          "1.6",
          "2",
          "2.5",
          "3",
        ]),
        //配置表情
        (this.editor.customConfig.emotions = [
          {
            title: "新浪", // tab 的标题
            type: "image", // 'emoji' 或 'image' ，即 emoji 形式或者图片形式
            content: [
              {
                alt: "[坏笑]",
                src: `${SINA_URL_PATH}/50/pcmoren_huaixiao_org.png`,
              },
              {
                alt: "[舔屏]",
                src: `${SINA_URL_PATH}/40/pcmoren_tian_org.png`,
              },
              { alt: "[污]", src: `${SINA_URL_PATH}/3c/pcmoren_wu_org.png` },
            ],
          },
          {
            title: "emoji", // tab 的标题
            type: "emoji", // 'emoji' / 'image'
            // emoji 表情，content 是一个数组即可
            content:
              "😀 😃 😄 😁 😆 😅 😂 😊 😇 🙂 🙃 😉 😓 😪 😴 🙄 🤔 😬 🤐".split(
                /\s/
              ),
          },
        ]),
        (this.editor.customConfig.uploadImgHooks = {
          fail: (xhr, editor, result) => {
            // 插入图片失败回调
          },
          success: (xhr, editor, result) => {
            // 图片上传成功回调
          },
          timeout: (xhr, editor) => {
            // 网络超时的回调
          },
          error: (xhr, editor) => {
            // 图片上传错误的回调
          },
          customInsert: (insertImg, result, editor) => {
            console.log("121212");
            // 图片上传成功，插入图片的回调
            //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
            // //console.log(result.data[0].url)
            //insertImg()为插入图片的函数
            //循环插入图片
            // for (let i = 0; i < 1; i++) {
            // //console.log(result)
            let url = "http://otp.cdinfotech.top" + result.url;
            insertImg(url);
            // }
          },
        });
      this.editor.customConfig.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        this.$emit("change", this.info_); // 将内容同步到父组件中
      };

      // 创建富文本编辑器
      this.editor.create();
      // this.editor.fullscreen.init('.editor');
    },
    filesToBase64(files) {
      const _this = this;
      files.map((item) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          _this.base64ToUrl(e.target.result, item);
          // _this.uploadImg(e.target.result, item);
        };
        // 传入一个参数对象即可得到基于该参数对象的文本内容
        reader.readAsDataURL(item);
      });
    },
    base64ToUrl(base64, item) {
      var arr = base64.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const blob = new Blob([u8arr], { type: mime });
      this.uploadImg(blob, item);
      // return blob;
    },
    uploadImg(blob, file) {
      let fd = new FormData();
      let file_lastname = /\.[^\.]+$/.exec(file.name);
      fd.append("file", blob);
      fd.append("serialnumber", "5");
      fd.append("ContentType", file_lastname);
      this.$remote
        .post("uploading/uploadimg/file/uploadimg", fd, {
          headers: {
            "Content-Type": "multipart/form-data", //文件上传时的格式
          },
        })
        .then((response) => {
          if (response.code == 20000) {
            if (response.data) {
              // insertImg(response.data)
              this.editor.cmd.do(
                "insertHtml",
                '<img src="' + response.data + '" style="max-width:100px;max-height:70px"/>'
              );
              console.log(response);
            }
          } else {
            this.$message(response.message);
          }
        });
    },
  },
};
</script>

<style lang="css">
.editor {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.toolbar {
  border: 1px solid #ccc;
  overflow: auto;
  height: 100px;
}
.text {
  border: 1px solid #ccc;
  /* max-height: 200px;
    height: 200px; */
}
</style>
